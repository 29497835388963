import React, { useState, useEffect } from 'react';
import './CurrentDate.css'

interface CurrentHijriDateProps {}

// Interface for representing date information
interface DateInfo {
  day: number;
  month: number;
  year: number;
  dayOfWeek: string;
}

const CurrentHijriDate: React.FC<CurrentHijriDateProps> = () => {
  const [CurrentHijriDate, setCurrentHijriDate] = useState<DateInfo | null>(null);

  useEffect(() => {
    const now = new Date();
    setCurrentHijriDate({
      day: now.getDate(),
      month: now.getMonth() + 1, // Months are zero-indexed
      year: now.getFullYear(),
      dayOfWeek: now.toLocaleDateString('en-US', { weekday: 'long' }),
    });
  }, []);

  if (!CurrentHijriDate) return null;

  return (
    <div className="current-date">
      <span>{CurrentHijriDate.dayOfWeek}</span>, {CurrentHijriDate.day} {getMonthName(CurrentHijriDate.month)} {CurrentHijriDate.year}
    </div>
  );
};

// Helper function to get month name
function getMonthName(month: number): string {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  return months[month - 1];
}

export default CurrentHijriDate;
