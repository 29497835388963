import './App.css';
import Clock from './components/Clock';
import CurrentDate from './components/CurrentDate';
import CurrentHijriDate from './components/CurrentHijriDate';
import { Logo } from './components/Logo';
import PrayerTimesList from './components/PrayerTimesList';
// Import the JSON data
import prayerTimesData from './prayerTimes.json';

interface PrayerTimeData {
  name: string;
  time: string;
  status?: string;
}



function App() {
  const prayerTimes = prayerTimesData.prayerTimes as PrayerTimeData[];
  //console.log(prayerTimes);
  return (
    <div className="App">
      <header className="App-header">
      <CurrentDate/>
     <Logo/>   
     <CurrentHijriDate/>  
      </header>
      <Clock/>
      <PrayerTimesList prayerTimes={prayerTimes} />
    </div>
  );
}

export default App;
